import { Controller } from "@hotwired/stimulus"
import AutoCompleteJS from "@tarekraafat/autocomplete.js"
import initAutocompleteBooks from "../autocomplete-books"
import { Notify } from 'notiflix/build/notiflix-notify-aio'

// Connects to data-controller="add-book"
export default class extends Controller {
  static targets = ["urlInput", "titleInput", "authorsInput", "submitButton", "manualSection", "urlSection"]
  static values = {
    userListId: Number
  }

  connect() {
    this.initializeAutocomplete()
  }

  initializeAutocomplete() {
    const inputElement = this.element.querySelector('#bookSearchAutocomplete')
    if (!inputElement) return

    const config = initAutocompleteBooks(
      '/autocomplete', 
      '#bookSearchAutocomplete', 
      this.handleBookSelection.bind(this),
      true
    )

    this.autocomplete = new AutoCompleteJS(config)
  }

  validateForm() {
    // Get all URL inputs
    const urlValues = this.urlInputTargets.map(input => input.value.trim())
    const hasUrl = urlValues.some(value => value.length > 0)

    // Get manual inputs
    const title = this.titleInputTarget.value.trim()
    const authors = this.authorsInputTarget.value.trim()

    // Show/hide sections based on input
    if (hasUrl) {
      this.manualSectionTarget.classList.add('d-none')
      // Clear manual inputs when hiding
      this.titleInputTarget.value = ''
      this.authorsInputTarget.value = ''
    } else {
      this.manualSectionTarget.classList.remove('d-none')
    }

    if (title.length > 0 || authors.length > 0) {
      this.urlSectionTarget.classList.add('d-none')
      // Clear URL inputs when hiding
      this.urlInputTargets.forEach(input => input.value = '')
    } else {
      this.urlSectionTarget.classList.remove('d-none')
    }

    // Enable submit if either:
    // 1. At least one URL is provided, or
    // 2. Both title and authors are provided
    const isValid = hasUrl || (title.length > 0 && authors.length > 0)
    
    this.submitButtonTarget.disabled = !isValid
  }

  async addBookToList(bookId) {
    const response = await fetch(`/user_lists/${this.userListIdValue}/user_list_books`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        page_source: 'add_book_modal',
        user_list_book: {
          book_id: bookId
        }
      })
    })

    if (!response.ok) {
      throw new Error('Failed to add book to list')
    }

    // On success, reload the page
    window.location.reload()
  }

  async handleBookSelection(event) {
    const feedback = event.detail
    const bookId = feedback.selection.value.id
    console.log("Selected book:", bookId)

    try {
      await this.addBookToList(bookId)
    } catch (error) {
      console.error('Error:', error)
      Notify.failure('Failed to add book to list. Please try again.')
    }
  }

  showManualForm() {
    const manualForm = this.element.querySelector('#manualAddForm')
    const noMatchesSection = this.element.querySelector('#noMatchesSection')
    
    manualForm.classList.remove('d-none')
    noMatchesSection.classList.add('d-none')
  }

  hideManualForm() {
    const manualForm = this.element.querySelector('#manualAddForm')
    manualForm.classList.add('d-none')
  }

  async submitForm(event) {
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)

    // Disable submit button and show loading state
    this.submitButtonTarget.disabled = true
    const originalButtonText = this.submitButtonTarget.innerHTML
    this.submitButtonTarget.innerHTML = `
      <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      Adding Book...
    `

    try {
      // First create the book
      const response = await fetch('/books', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(Object.fromEntries(formData))
      })

      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.errors?.join(', ') || 'Failed to create book')
      }

      const book = await response.json()
      
      // Now add the book to the user list
      await this.addBookToList(book.id)

    } catch (error) {
      console.error('Error:', error)
      
      // Reset submit button
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.innerHTML = originalButtonText
      
      Notify.failure('Unable to add book. Please try again.')
    }
  }
}
