// sign-in-with-google

import {initializeApp}  from 'firebase/app'
import {getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail, isSignInWithEmailLink, signInWithEmailLink, sendSignInLinkToEmail, signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, OAuthProvider, EmailAuthProvider, onAuthStateChanged, signInWithRedirect, getRedirectResult} from 'firebase/auth'


let fetchCsrfTokenHappened = false;

function resetCsrfTokenFlag() {
  fetchCsrfTokenHappened = false;
}

function getCsrfTokenStatus() {
  return fetchCsrfTokenHappened;
}

function fetchCsrfToken() {
  return new Promise((resolve, reject) => {
    if (fetchCsrfTokenHappened) {
      resolve(true); // Resolve the promise with the value true
    } else {
      fetch('/csrf', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        const csrfToken = data.csrf_token;

        // Find all inputs with name "authenticity_token" and set their value
        const authenticityTokenInputs = document.querySelectorAll('input[name="authenticity_token"]');
        authenticityTokenInputs.forEach(input => {
          input.value = csrfToken;
        });

        // Update the meta tag for CSRF token in the document's head
        const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
        if (csrfMetaTag) {
          csrfMetaTag.setAttribute('content', csrfToken);
        } else {
          console.warn('No CSRF meta tag found in the document.');
        }

        fetchCsrfTokenHappened = true;
        resolve(true); // Resolve the promise after updating the CSRF token
      })
      .catch(error => {
        console.error('Error fetching CSRF token:', error);
        reject(error); // Reject the promise in case of an error
      });
    }
  });
}


function initFirebaseApp() {
  // Check the current hostname
  const isDev = window.location.hostname === "dev.thegreatestbooks.org";
  console.log("isDev: ", isDev)
  
  const firebaseConfig = {
    apiKey: "AIzaSyCrsrT_18mS1K8S5WImMJ7i8DE0a4oAdYI",
    // Set authDomain based on the current hostname
    authDomain: isDev ? "dev.thegreatestbooks.org" : "thegreatestbooks.org",
    projectId: "the-greatest-books",
    storageBucket: "the-greatest-books.appspot.com",
    messagingSenderId: "735268360576",
    appId: "1:735268360576:web:01ae98f0644a16c25bf165",
    measurementId: "G-NNXC2XRY9X"
  };
  
  return initializeApp(firebaseConfig);
}

function signOut(app) {
  console.log("inside signOut");
  return getAuth(app).signOut();
}

function googleLogin(app, event) {
  event.preventDefault();  // Prevents the link from being followed
  event.stopPropagation(); // Stops the event from propagating to parent elements
  const auth = getAuth(app);

  // Sign in using a redirect.
  const provider = new GoogleAuthProvider();
  // Start a sign in process for an unauthenticated user.
  provider.addScope('profile');
  provider.addScope('email');
  signInWithRedirect(auth, provider);
}

function appleLogin(app, event) {
  event.preventDefault();  // Prevents the link from being followed
  event.stopPropagation(); // Stops the event from propagating to parent elements
  const auth = getAuth(app);
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  signInWithRedirect(auth, provider);
}

function facebookLogin(app, event) {
  event.preventDefault();  // Prevents the link from being followed
  event.stopPropagation(); // Stops the event from propagating to parent elements
  const auth = getAuth(app);

  // Sign in using a redirect.
  const provider = new FacebookAuthProvider();
  // Start a sign in process for an unauthenticated user.
  provider.addScope('public_profile');
  provider.addScope('email');
  signInWithRedirect(auth, provider);
}

function twitterLogin(app, event) {
  event.preventDefault();  // Prevents the link from being followed
  event.stopPropagation(); // Stops the event from propagating to parent elements
  const auth = getAuth(app);

  // Sign in using a redirect.
  const provider = new TwitterAuthProvider();
  // Start a sign in process for an unauthenticated user.
  // provider.addScope('public_profile');
  // provider.addScope('email');
  signInWithRedirect(auth, provider);
}

function createUserWithEmailAndPwd(email, password) {
  const app = initFirebaseApp();
  const auth = getAuth(app);
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      return loginSuccess(userCredential);
    })
    .catch((error) => {
      const errorCode = error.code;
      console.log("createUserWithEmailAndPwd failed with code: ", errorCode);
      const errorMessage = error.message;
      if (errorCode === 'auth/email-already-in-use') {
        // User already exists, try to login instead
        console.log('User already exists, attempting to log in:', errorMessage);
        return loginUserWithEmailAndPwd(email, password)
          .then(loginSuccessStatus => {
            console.log('User logged in successfully after existing check: ', loginSuccessStatus);
            setTimeout(function() {
              window.location.href = '/';
            }, 2000);
            return loginSuccessStatus;
          })
          .catch(loginError => {
            console.error('Error logging in existing user: ', loginError);
            throw loginError; // Rethrow or handle as needed
          });
      } else {
        // Handle other errors
        console.log("createUserWithEmailAndPwd error: ", errorMessage);
        throw error; // Rethrow the original error if it's not the "email-already-in-use" case
      }
    });
}


function sendResetPasswordEmail(email) {
  return new Promise((resolve, reject) => {
    const app = initFirebaseApp();
    const auth = getAuth(app);

    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        resolve('Password reset email sent!');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // Log or handle the error as needed
        console.log("sendResetPasswordEmail error: ", errorMessage);
        // Rethrow the exact same error
        reject(error);
      });
  });
}


function loginUserWithEmailAndPwd(email, password) {
  return new Promise((resolve, reject) => {
    console.log("email: ", email);
    console.log("password: ", password);
    const app = initFirebaseApp();
    const auth = getAuth(app);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log('signInWithEmailAndPassword maybe success userCredential: ', userCredential)
        return loginSuccess(userCredential);
      })
      .then((loginSuccessStatus) => {
        console.log('signInWithEmailAndPassword loginSuccessStatus: ', loginSuccessStatus)
        // Successfully logged in and handled by loginSuccess
        resolve(loginSuccessStatus);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("loginUserWithEmailAndPwd error: ", errorMessage);
        // Rethrow the exact same error
        reject(error);
      });
  });
}


function sendEmailLoginLink(email) {
  const app = initFirebaseApp();
  const auth = getAuth(app);

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://thegreatestbooks.org/users/finish_email_signup',
    // This must be true.
    handleCodeInApp: true
  };

  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("sendEmailLoginLink error: ", errorMessage)
      // ...
    });
}

function loginWithEmailLink(email, password) {
  const app = initFirebaseApp();
  const auth = getAuth(app);

  if (isSignInWithEmailLink(auth, window.location.href)) {
    console.log('loginWithEmailLink isSignInWithEmailLink success')
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    // let email = window.localStorage.getItem('emailForSignIn');
    // if (!email) {
    //   // User opened the link on a different device. To prevent session fixation
    //   // attacks, ask the user to provide the associated email again. For example:
    //   email = window.prompt('Please provide your email for confirmation');
    // }
    // The client SDK will parse the code from the link for you.

    return createUserWithEmailAndPwd(email, password)
  }
}


function addAuthClickEvents(app) {
  var googleLoginBtn = document.getElementById("sign-in-with-google");
  if (googleLoginBtn) {
    googleLoginBtn.addEventListener('click', googleLogin.bind(null, app));
  }

  var facebookLoginBtn = document.getElementById("sign-in-with-facebook");
  if (facebookLoginBtn) {
    facebookLoginBtn.addEventListener('click', facebookLogin.bind(null, app));
  }

  var twitterLoginBtn = document.getElementById("sign-in-with-twitter");
  if (twitterLoginBtn) {
    twitterLoginBtn.addEventListener('click', twitterLogin.bind(null, app));
  }

  var appleLoginBtn = document.getElementById("sign-in-with-apple");
  if (appleLoginBtn) {
    appleLoginBtn.addEventListener('click', appleLogin.bind(null, app));
  }
}


function loginSuccess(result) {
  console.log('loginSuccess result: ', result);
  if (result) {
    console.log('waitForLoginRedirect triggered! result ', result);
    const user = result.user;

    if (user) {
      window.localStorage.removeItem('emailForSignIn');
      console.log('waitForLoginRedirect: user is logged in: ', user);

      const authDataInput = document.getElementById('auth_data_json');
      console.log("authDataInput: ", authDataInput)
      const userJson = JSON.stringify(user);
      console.log("setting input to: ", userJson)
      authDataInput.setAttribute('value', userJson);

      var form = document.getElementById('sign-in-form');
      const payload = new FormData(form);
      payload.set("auth_data_json", userJson)
      console.log("------ login payload debug -------")
      for (const [key, value] of payload) {
        console.log(key, value);
      }
      console.log('loginSuccess: posting to /users/sign_in with payload: ', payload)
      return fetch('/users/sign_in', {
        method: 'POST',
        body: payload
      }).then(function(response) {
        console.log('loginSuccess /users/sign_in response: ', response)
        return response.status
      }).then(function(body) {
        const pathName = window.location.pathname;
        const shouldReload = document.body.dataset.reloadAfterAuth === 'true';
        
        if (pathName.endsWith('/finish_email_signup')) {
          // Redirect to root page for email signup completion
          window.location.href = '/';
        } else if (shouldReload) {
          // Reload the current page for other cases
          window.location.reload();
        } else {
          const userBookActionsForm = document.getElementById("user-book-actions-form")
          console.log("Submitting the user book actions form")
          userBookActionsForm.requestSubmit();

          // Select the span element by its ID
          var span = document.getElementById("survey-btn");
          if (span) {
              var buttonHTML = '<button class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#survey-modal">Start Survey</button>';
              span.innerHTML = buttonHTML;
          }
        }
      });
    }
  }
}

function initAuthEvents(app) {
  const auth = getAuth(app);
  console.log('initAuthEvents called: ', auth)
  getRedirectResult(auth).then(loginSuccess).catch(error => {
    // Handle the error if the promise is rejected
    console.log("error.code: ", error.code);
    console.log('raw error: ', error);
    if (error.code === 'auth/account-exists-with-different-credential') {
      console.log("err: ", error.data);
      const prevUser = error.auth.currentUser;
      console.log("prevUser: ", prevUser);
      console.log('this user registered with a different provider');
      signOut(app)
    }
    console.log('getRedirectResult auth: ', auth);
    console.error(error);
  });

  auth.onAuthStateChanged(function(user) {
    console.log('onAuthStateChanged auth user: ', user);
    if (user) {
      console.log('onAuthStateChanged: user is logged in');
    } else {
      console.log("inside DOMContentLoaded no user signed in");
      const readingStatsElement = document.getElementById("reading_stats_container");
      if (readingStatsElement) {
        readingStatsElement.style.display = 'none';
    }
    }
  });
}

export {
  addAuthClickEvents,
  initAuthEvents,
  signOut,
  initFirebaseApp,
  createUserWithEmailAndPwd,
  loginUserWithEmailAndPwd,
  sendEmailLoginLink,
  loginWithEmailLink,
  sendResetPasswordEmail,
  fetchCsrfToken,
  fetchCsrfTokenHappened,
  resetCsrfTokenFlag,
  getCsrfTokenStatus,
  loginSuccess
};