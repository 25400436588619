import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="csv-download"
export default class extends Controller {
  static targets = ["downloadLink", "loginMessage"]
  
  connect() {
    this.checkCount = 0
    this.maxChecks = 10 // Will check 10 times
    this.checkInterval = 500 // Every 500ms
    
    this.startStatusCheck()
    this.observeAuthStatus()
  }

  startStatusCheck() {
    const check = () => {
      const loggedInElement = document.querySelector('[data-logged-in="true"]')
      const isLoggedIn = loggedInElement !== null

      if (isLoggedIn || this.checkCount >= this.maxChecks) {
        this.toggleDownload(isLoggedIn)
      } else {
        this.checkCount++
        setTimeout(check, this.checkInterval)
      }
    }

    check()
  }

  observeAuthStatus() {
    const authFrame = document.querySelector('#auth-status-frame')
    if (!authFrame) return

    const observer = new MutationObserver(() => {
      this.checkCount = 0
      this.startStatusCheck()
    })

    observer.observe(authFrame, { 
      childList: true, 
      subtree: true,
      attributes: true,
      attributeFilter: ['data-logged-in']
    })
  }

  toggleDownload(isLoggedIn) {
    if (this.hasDownloadLinkTarget && this.hasLoginMessageTarget) {
      if (isLoggedIn) {
        this.downloadLinkTarget.classList.remove('d-none')
        this.loginMessageTarget.classList.add('d-none')
      } else {
        this.downloadLinkTarget.classList.add('d-none')
        this.loginMessageTarget.classList.remove('d-none')
      }
    }
  }
}
