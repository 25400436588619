import AutoCompleteJS from "@tarekraafat/autocomplete.js"
import initAutocomplete from "./autocomplete-base"

window.addEventListener('DOMContentLoaded', (event) => {
  const inputClass = 'search-country-autocomplete';
  const autocompleteCountryElements = document.getElementsByClassName(inputClass);

  for (let element of autocompleteCountryElements) {
    const elementId = element.id;

    const config = {
      url: '/countries/search.json',
      selector: '#' + elementId,
      queryParam: 'filter',
      searchKeys: ['name'],
      placeHolder: "Search for countries...",
      selectionFn: function(e) {
        const selection = e.detail.selection.value;
        const event = new CustomEvent('countrySelected', {
          detail: {
            selection: e.detail.selection,
            inputElement: element
          }
        });
        document.dispatchEvent(event);
        element.value = ''; // Clear the input after selection
      }
    };

    // Initialize the autocomplete for the current element
    const autoCompleteJS = new AutoCompleteJS(initAutocomplete(config));
  }
}); 
