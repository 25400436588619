import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    console.log("JSON Modal controller connected")
    this.modal = new Modal(document.getElementById("jsonPreviewModal"))
    console.log("Modal initialized:", this.modal)
  }

  show(event) {
    console.log("Show action triggered")
    event.preventDefault()
    
    const button = event.currentTarget
    console.log("Button clicked:", button)
    console.log("Button dataset:", button.dataset)
    
    const contentId = button.dataset.contentId
    console.log("Content ID:", contentId)
    
    const contentElement = document.getElementById(contentId)
    console.log("Content element found:", contentElement)
    
    if (!contentElement) {
      console.error("Could not find element with ID:", contentId)
      return
    }
    
    const content = JSON.parse(contentElement.textContent)
    console.log("Parsed content:", content)
    
    // Set the modal title based on the content type
    const title = contentId.startsWith('messages') ? "Messages Preview" : "Responses Preview"
    document.getElementById("jsonPreviewModalLabel").textContent = title

    document.querySelector("#jsonPreviewModal .json-display").textContent = JSON.stringify(content, null, 2)
    this.modal.show()
    console.log("Modal shown")
  }
} 