import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="advanced-search"
export default class extends Controller {
  static targets = [
    "includedCategoriesContainer",
    "excludedCategoriesContainer",
    "includedLanguagesContainer",
    "excludedLanguagesContainer",
    "includedCountriesContainer",
    "excludedCountriesContainer",
    "form"
  ]

  static values = {
    includedCategories: { type: Array, default: [] },
    excludedCategories: { type: Array, default: [] },
    includedLanguages: { type: Array, default: [] },
    excludedLanguages: { type: Array, default: [] },
    includedCountries: { type: Array, default: [] },
    excludedCountries: { type: Array, default: [] },
    initialIncludedCategories: { type: Array, default: [] },
    initialExcludedCategories: { type: Array, default: [] },
    initialIncludedLanguages: { type: Array, default: [] },
    initialExcludedLanguages: { type: Array, default: [] },
    initialIncludedCountries: { type: Array, default: [] },
    initialExcludedCountries: { type: Array, default: [] }
  }

  connect() {
    // Initialize with any existing values
    this.initializeExistingValues()
    
    // Initialize autocomplete handlers
    this.initializeAutocompletes()
    
    // Update input states based on initial values
    this.updateInputStates()
  }

  updateInputStates() {
    // Categories don't need mutual exclusivity - both inclusions and exclusions are allowed
    
    // Languages
    const includedLanguagesInput = document.getElementById('included-languages')
    const excludedLanguagesInput = document.getElementById('excluded-languages')
    this.updateInputPairState(includedLanguagesInput, excludedLanguagesInput,
      this.includedLanguagesValue.length > 0, this.excludedLanguagesValue.length > 0)

    // Countries
    const includedCountriesInput = document.getElementById('included-countries')
    const excludedCountriesInput = document.getElementById('excluded-countries')
    this.updateInputPairState(includedCountriesInput, excludedCountriesInput,
      this.includedCountriesValue.length > 0, this.excludedCountriesValue.length > 0)
  }

  updateInputPairState(includeInput, excludeInput, hasIncludes, hasExcludes) {
    if (hasIncludes) {
      excludeInput.disabled = true
      excludeInput.placeholder = "Cannot exclude when includes are selected"
      excludeInput.parentElement.classList.add('opacity-50')
    } else if (hasExcludes) {
      includeInput.disabled = true
      includeInput.placeholder = "Cannot include when excludes are selected"
      includeInput.parentElement.classList.add('opacity-50')
    } else {
      includeInput.disabled = false
      excludeInput.disabled = false
      includeInput.placeholder = "Search to include..."
      excludeInput.placeholder = "Search to exclude..."
      includeInput.parentElement.classList.remove('opacity-50')
      excludeInput.parentElement.classList.remove('opacity-50')
    }
  }

  initializeExistingValues() {
    // Set initial values from data attributes
    if (this.initialIncludedCategoriesValue.length > 0) {
      this.includedCategoriesValue = this.initialIncludedCategoriesValue
      this.renderIncludedCategories()
    }
    
    if (this.initialExcludedCategoriesValue.length > 0) {
      this.excludedCategoriesValue = this.initialExcludedCategoriesValue
      this.renderExcludedCategories()
    }
    
    if (this.initialIncludedLanguagesValue.length > 0) {
      this.includedLanguagesValue = this.initialIncludedLanguagesValue
      this.renderIncludedLanguages()
    }
    
    if (this.initialExcludedLanguagesValue.length > 0) {
      this.excludedLanguagesValue = this.initialExcludedLanguagesValue
      this.renderExcludedLanguages()
    }
    
    if (this.initialIncludedCountriesValue.length > 0) {
      this.includedCountriesValue = this.initialIncludedCountriesValue
      this.renderIncludedCountries()
    }
    
    if (this.initialExcludedCountriesValue.length > 0) {
      this.excludedCountriesValue = this.initialExcludedCountriesValue
      this.renderExcludedCountries()
    }
  }

  initializeAutocompletes() {
    // The autocomplete initialization is handled by the separate JS files
    // We just need to set up the selection handlers
    document.addEventListener('categorySelected', this.handleCategorySelection.bind(this))
    document.addEventListener('languageSelected', this.handleLanguageSelection.bind(this))
    document.addEventListener('countrySelected', this.handleCountrySelection.bind(this))
  }

  handleCategorySelection(event) {
    const { selection, inputElement } = event.detail
    const isExcluded = inputElement.dataset.type === 'excluded'
    const category = selection.value

    if (isExcluded) {
      this.addExcludedCategory(category)
    } else {
      this.addIncludedCategory(category)
    }
  }

  handleLanguageSelection(event) {
    const { selection, inputElement } = event.detail
    const isExcluded = inputElement.dataset.type === 'excluded'
    const language = selection.value

    if (isExcluded) {
      this.addExcludedLanguage(language)
    } else {
      this.addIncludedLanguage(language)
    }
  }

  handleCountrySelection(event) {
    const { selection, inputElement } = event.detail
    const isExcluded = inputElement.dataset.type === 'excluded'
    const country = selection.value

    if (isExcluded) {
      this.addExcludedCountry(country)
    } else {
      this.addIncludedCountry(country)
    }
  }

  addIncludedCategory(category) {
    if (!this.includedCategoriesValue.find(c => c.id === category.id)) {
      this.includedCategoriesValue = [...this.includedCategoriesValue, category]
      this.renderIncludedCategories()
      this.updateInputStates()
    }
  }

  addExcludedCategory(category) {
    if (!this.excludedCategoriesValue.find(c => c.id === category.id)) {
      this.excludedCategoriesValue = [...this.excludedCategoriesValue, category]
      this.renderExcludedCategories()
      this.updateInputStates()
    }
  }

  addIncludedLanguage(language) {
    if (!this.includedLanguagesValue.find(l => l.id === language.id)) {
      this.includedLanguagesValue = [...this.includedLanguagesValue, language]
      this.renderIncludedLanguages()
      this.updateInputStates()
    }
  }

  addExcludedLanguage(language) {
    if (!this.excludedLanguagesValue.find(l => l.id === language.id)) {
      this.excludedLanguagesValue = [...this.excludedLanguagesValue, language]
      this.renderExcludedLanguages()
      this.updateInputStates()
    }
  }

  addIncludedCountry(country) {
    if (!this.includedCountriesValue.find(c => c.id === country.id)) {
      this.includedCountriesValue = [...this.includedCountriesValue, country]
      this.renderIncludedCountries()
      this.updateInputStates()
    }
  }

  addExcludedCountry(country) {
    if (!this.excludedCountriesValue.find(c => c.id === country.id)) {
      this.excludedCountriesValue = [...this.excludedCountriesValue, country]
      this.renderExcludedCountries()
      this.updateInputStates()
    }
  }

  removeIncludedCategory(event) {
    const categoryId = event.currentTarget.dataset.categoryId
    this.includedCategoriesValue = this.includedCategoriesValue.filter(c => c.id !== parseInt(categoryId))
    this.renderIncludedCategories()
    this.updateInputStates()
  }

  removeExcludedCategory(event) {
    const categoryId = event.currentTarget.dataset.categoryId
    this.excludedCategoriesValue = this.excludedCategoriesValue.filter(c => c.id !== parseInt(categoryId))
    this.renderExcludedCategories()
    this.updateInputStates()
  }

  removeIncludedLanguage(event) {
    const languageId = event.currentTarget.dataset.languageId
    this.includedLanguagesValue = this.includedLanguagesValue.filter(l => l.id !== parseInt(languageId))
    this.renderIncludedLanguages()
    this.updateInputStates()
  }

  removeExcludedLanguage(event) {
    const languageId = event.currentTarget.dataset.languageId
    this.excludedLanguagesValue = this.excludedLanguagesValue.filter(l => l.id !== parseInt(languageId))
    this.renderExcludedLanguages()
    this.updateInputStates()
  }

  removeIncludedCountry(event) {
    const countryId = event.currentTarget.dataset.countryId
    this.includedCountriesValue = this.includedCountriesValue.filter(c => c.id !== parseInt(countryId))
    this.renderIncludedCountries()
    this.updateInputStates()
  }

  removeExcludedCountry(event) {
    const countryId = event.currentTarget.dataset.countryId
    this.excludedCountriesValue = this.excludedCountriesValue.filter(c => c.id !== parseInt(countryId))
    this.renderExcludedCountries()
    this.updateInputStates()
  }

  renderSelectedItems(items, containerId, removeAction, idField) {
    const container = this[`${containerId}Target`]
    container.innerHTML = items.map(item => `
      <span class="badge bg-primary me-2 mb-2">
        ${item.name}
        <button type="button" class="btn-close btn-close-white ms-2" 
          data-action="click->${removeAction}" 
          data-${idField}="${item.id}">
        </button>
      </span>
    `).join('')
  }

  renderIncludedCategories() {
    this.renderSelectedItems(
      this.includedCategoriesValue, 
      'includedCategoriesContainer',
      'advanced-search#removeIncludedCategory',
      'category-id'
    )
  }

  renderExcludedCategories() {
    this.renderSelectedItems(
      this.excludedCategoriesValue, 
      'excludedCategoriesContainer',
      'advanced-search#removeExcludedCategory',
      'category-id'
    )
  }

  renderIncludedLanguages() {
    this.renderSelectedItems(
      this.includedLanguagesValue, 
      'includedLanguagesContainer',
      'advanced-search#removeIncludedLanguage',
      'language-id'
    )
  }

  renderExcludedLanguages() {
    this.renderSelectedItems(
      this.excludedLanguagesValue, 
      'excludedLanguagesContainer',
      'advanced-search#removeExcludedLanguage',
      'language-id'
    )
  }

  renderIncludedCountries() {
    this.renderSelectedItems(
      this.includedCountriesValue, 
      'includedCountriesContainer',
      'advanced-search#removeIncludedCountry',
      'country-id'
    )
  }

  renderExcludedCountries() {
    this.renderSelectedItems(
      this.excludedCountriesValue, 
      'excludedCountriesContainer',
      'advanced-search#removeExcludedCountry',
      'country-id'
    )
  }

  submit(event) {
    event.preventDefault()
    
    // Create a new FormData object from the form
    const form = this.formTarget
    const formData = new FormData(form)
    
    // Remove any existing array fields to prevent duplicates
    form.querySelectorAll('input[name$="[included_category_ids][]"]').forEach(el => el.remove())
    form.querySelectorAll('input[name$="[excluded_category_ids][]"]').forEach(el => el.remove())
    form.querySelectorAll('input[name$="[included_language_ids][]"]').forEach(el => el.remove())
    form.querySelectorAll('input[name$="[excluded_language_ids][]"]').forEach(el => el.remove())
    form.querySelectorAll('input[name$="[included_country_ids][]"]').forEach(el => el.remove())
    form.querySelectorAll('input[name$="[excluded_country_ids][]"]').forEach(el => el.remove())
    
    // Add hidden fields for each selected category
    this.includedCategoriesValue.forEach(category => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'saved_search[criteria][included_category_ids][]'
      input.value = category.id
      form.appendChild(input)
    })
    
    this.excludedCategoriesValue.forEach(category => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'saved_search[criteria][excluded_category_ids][]'
      input.value = category.id
      form.appendChild(input)
    })
    
    // Add hidden fields for each selected language
    this.includedLanguagesValue.forEach(language => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'saved_search[criteria][included_language_ids][]'
      input.value = language.id
      form.appendChild(input)
    })
    
    this.excludedLanguagesValue.forEach(language => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'saved_search[criteria][excluded_language_ids][]'
      input.value = language.id
      form.appendChild(input)
    })
    
    // Add hidden fields for each selected country
    this.includedCountriesValue.forEach(country => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'saved_search[criteria][included_country_ids][]'
      input.value = country.id
      form.appendChild(input)
    })
    
    this.excludedCountriesValue.forEach(country => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'saved_search[criteria][excluded_country_ids][]'
      input.value = country.id
      form.appendChild(input)
    })
    
    // Submit the form
    form.submit()
  }
}
