import { Controller } from "@hotwired/stimulus"
import AirDatepicker from 'air-datepicker'
import 'air-datepicker/air-datepicker.css'

export default class extends Controller {
  connect() {
    new AirDatepicker(this.element, {
      dateFormat: this.formatValue
    })
  }

  get formatValue() {
    return this.data.get("formatValue") || "yyyy-MM-dd"
  }
} 