export default function initAutocomplete(config) {
  const defaultConfig = {
    placeHolder: "Search...",
    data: {
      src: async (query) => {
        try {
          const params = new URLSearchParams({
            [config.queryParam]: query,
            ...(config.additionalParams || {})
          });
          
          const source = await fetch(`${config.url}?${params}`);
          const data = await source.json();
          return data;
        } catch (error) {
          return error;
        }
      },
      keys: config.searchKeys || ["name"]
    },
    resultsList: {
      tag: "ul",
      class: "list-group autocomplete-results",
    },
    resultItem: {
      tag: "li",
      class: "list-group-item list-group-item-secondary autocomplete-results-item"
    },
    selector: config.selector,
    events: {
      input: {
        selection: config.selectionFn
      }
    }
  };

  // Merge any additional config options
  return { ...defaultConfig, ...config.additionalConfig };
} 