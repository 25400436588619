import { Controller } from "@hotwired/stimulus"
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth, sendPasswordResetEmail } from "firebase/auth"
import { initFirebaseApp, loginSuccess } from "../authentication"

console.log("📥 Loading EmailSignInController module")

export default class extends Controller {
  static targets = ["email", "password", "alert"]

  constructor(...args) {
    super(...args)
    console.log("🏗️ EmailSignInController constructor called")
  }

  connect() {
    console.log("🔌 Email Sign In Controller Connected")
    // Initialize Firebase when controller connects
    this.app = initFirebaseApp()
    this.auth = getAuth(this.app)
    console.log("🔥 Firebase initialized:", this.auth ? "success" : "failed")
  }

  async checkExistingAccount(email) {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content
      const response = await fetch('/users/account_exists', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({ email })
      })
      const data = await response.json()
      return {
        exists: data.exists,
        migrated: data.migrated,
        providers: data.providers
      }
    } catch (error) {
      console.error("Error checking existing account:", error)
      return { exists: false, migrated: false }
    }
  }

  showSuccess(message) {
    this.alertTarget.textContent = message
    this.alertTarget.classList.remove('alert-danger')
    this.alertTarget.classList.add('alert-success')
    this.alertTarget.classList.remove('d-none')
  }

  showError(message) {
    this.alertTarget.textContent = message
    this.alertTarget.classList.remove('alert-success')
    this.alertTarget.classList.add('alert-danger')
    this.alertTarget.classList.remove('d-none')
  }

  hideError() {
    this.alertTarget.textContent = ''
    this.alertTarget.classList.add('d-none')
    this.alertTarget.classList.remove('alert-success', 'alert-danger')
  }

  async resetPassword(event) {
    event.preventDefault()
    this.hideError()

    const email = this.emailTarget.value
    console.log("📧 Reset password email:", email)
    if (!email) {
      this.showError("Please enter your email address to reset your password.")
      return
    }

    try {
      await sendPasswordResetEmail(this.auth, email)
      this.showSuccess("Password reset email sent! Please check your inbox.")
    } catch (error) {
      console.error("❌ Error sending password reset:", error)
      
      switch (error.code) {
        case 'auth/user-not-found':
          this.showError("No account found with this email address.")
          break
        case 'auth/invalid-email':
          this.showError("Please enter a valid email address.")
          break
        case 'auth/too-many-requests':
          this.showError("Too many password reset attempts. Please try again later.")
          break
        default:
          this.showError("An error occurred while sending the password reset email.")
      }
    }
  }

  async createAccount(event) {
    console.log("📝 Create Account triggered", event)
    event.preventDefault()
    this.hideError()
    
    const email = this.emailTarget.value
    const password = this.passwordTarget.value
    console.log("📧 Attempting to create account for email:", email)

    // Check if account exists first
    const accountStatus = await this.checkExistingAccount(email)
    
    if (accountStatus.exists && accountStatus.migrated) {
      if (accountStatus.providers.length > 0) {
        let providerList;
        if (accountStatus.providers.length === 1) {
          providerList = accountStatus.providers[0];
        } else if (accountStatus.providers.length === 2) {
          providerList = `${accountStatus.providers[0]} and ${accountStatus.providers[1]}`;
        } else {
          const lastProvider = accountStatus.providers.pop();
          providerList = `${accountStatus.providers.join(', ')}, and ${lastProvider}`;
        }
        this.showError(`You have previously signed in using ${providerList}, please use one of those instead.`);
      } else {
        this.showError("An account already exists with this email address. Please use the 'Forgot Password?' button to reset your password.");
      }
      return;
    }

    try {
      console.log("🔑 Calling createUserWithEmailAndPassword...")
      const userCredential = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      )
      
      console.log("✅ Account created successfully:", userCredential)
      console.log("🔄 Calling loginSuccess...")
      const loginResult = await loginSuccess(userCredential)
      console.log("✨ LoginSuccess completed, redirecting...", loginResult)
      window.location.href = window.location.pathname
      
    } catch (error) {
      console.error("❌ Error creating account:", error)
      
      let errorMessage = "An error occurred while creating your account."
      
      switch (error.code) {
        case 'auth/email-already-in-use':
          console.log("📨 Email already exists, attempting login...")
          try {
            const userCredential = await signInWithEmailAndPassword(this.auth, email, password)
            console.log("🔄 Existing user login successful, calling loginSuccess...")
            const loginResult = await loginSuccess(userCredential)
            console.log("✨ LoginSuccess completed for existing user, redirecting...", loginResult)
            window.location.href = window.location.pathname
            return
          } catch (loginError) {
            console.error("❌ Login attempt failed:", loginError)
            this.showError("An account already exists with this email address. Please check your password and try again.")
          }
          break
        case 'auth/weak-password':
          this.showError("Password should be at least 6 characters long.")
          break
        case 'auth/invalid-email':
          this.showError("Please enter a valid email address.")
          break
        default:
          this.showError(errorMessage)
      }
    }
  }

  async login(event) {
    console.log("🔑 Login triggered", event)
    event.preventDefault()
    this.hideError()
    
    const email = this.emailTarget.value
    const password = this.passwordTarget.value
    console.log("📧 Attempting login for email:", email)

    try {
      console.log("🔄 Calling signInWithEmailAndPassword...")
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password)
      console.log("✅ Login successful:", userCredential)
      console.log("🔄 Calling loginSuccess...")
      const loginResult = await loginSuccess(userCredential)
      console.log("✨ LoginSuccess completed, redirecting...", loginResult)
      window.location.href = window.location.pathname
    } catch (error) {
      console.error("❌ Error logging in:", error)
      
      let errorMessage = "An error occurred while logging in."
      
      switch (error.code) {
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          this.showError("Invalid email or password.")
          break
        case 'auth/invalid-email':
          this.showError("Please enter a valid email address.")
          break
        case 'auth/too-many-requests':
          this.showError("Too many failed login attempts. Please try again later.")
          break
        default:
          this.showError(errorMessage)
      }
    }
  }
} 