function checkFormsValidity(form_id, button_id, e) {
  var form = document.forms[form_id];
  var button = document.getElementById(button_id);
  if (form.checkValidity()) {
    button.disabled = false;
  } else {
    button.disabled = true;
  }
}

window.addEventListener('DOMContentLoaded', (event) => {
  var buttons = document.querySelectorAll('.new-custom-user-list-button');
  buttons.forEach(element => {
    element.addEventListener('click', function(e) {
      var book_id = e.target.dataset.book;

      const form = document.getElementById('create-custom-user-list-form');
      let bookIdInput = document.getElementById('new-custom-user-list-book-id-input');
      if (bookIdInput === null) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "book_id";
        input.value = book_id;
        input.id = 'new-custom-user-list-book-id-input';
        form.appendChild(input);
      } else {
        bookIdInput.value = book_id
      }
    });
  });

  var form = document.getElementById("create-custom-user-list-form");
  if (form !== null) {
    var inputs = document.getElementById("create-custom-user-list-form").elements;
    // Iterate over the form controls
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].nodeName === "INPUT" && inputs[i].type === "text" && inputs[i].getAttribute('required') !== undefined) {
        inputs[i].onkeyup = checkFormsValidity.bind(null, 'create-custom-user-list-form', 'create-custom-user-list-form-create-btn');
      }
    }
    checkFormsValidity('create-custom-user-list-form', 'create-custom-user-list-form-create-btn');
  }
});

export function assignEventsToSubmitUserListForms() {
  var linkSubmitBtns = document.getElementsByClassName('submit-link');
  for (i = 0; i < linkSubmitBtns.length; i++) {
    linkSubmitBtns[i].addEventListener('click', function(e) {
      e.preventDefault();
      e.target.parentNode.requestSubmit();
    });
  }
}

document.addEventListener('turbo:frame-load', function() {
  var formElement = document.querySelector('form.email-login-link-color');
  if (formElement) {
    formElement.addEventListener('click', function(event) {
      // Ensure the form itself or the hidden input was clicked, but not the button
      if (event.target === this || event.target.name === 'authenticity_token') {
          var button = this.querySelector('.text-button');
          if (button) {
              button.click();
          }
      }
    });
  }
});
