import AutoCompleteJS from "@tarekraafat/autocomplete.js"
import initAutocomplete from "./autocomplete-base"

window.addEventListener('DOMContentLoaded', (event) => {
  const inputClass = 'category-autocomplete';
  const autocompleteCategoryElements = document.getElementsByClassName(inputClass);

  for (let element of autocompleteCategoryElements) {
    const elementId = element.id;
    
    // Get category type from data attribute if present
    const categoryType = element.dataset.categoryType;

    const config = {
      url: '/genres/search.json',
      selector: '#' + elementId,
      queryParam: 'filter',
      searchKeys: ['name'],
      placeHolder: "Search for genres...",
      additionalParams: categoryType ? { category_type: categoryType } : {},
      selectionFn: function(e) {
        const clickedSlug = e.detail.selection.value.slug;
        const categoryLink = document.querySelector('.category-link');
        
        if (categoryLink) {
          const currentSlug = categoryLink.dataset.slug;
          const newHref = categoryLink.href.replace(currentSlug, clickedSlug);
          window.location.href = newHref;  
        }
      }
    };

    // Initialize the autocomplete for the current element
    const autoCompleteJS = new AutoCompleteJS(initAutocomplete(config));
  }
});
