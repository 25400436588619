import { Controller } from "@hotwired/stimulus"
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Modal } from "bootstrap"

// Connects to data-controller="user-list-books-update-form"
export default class extends Controller {
  connect() {
    this.element.addEventListener('turbo:submit-end', this.handleSubmit.bind(this))
  }

  handleSubmit(event) {
    if (event.detail.success) {
      // Find and close the modal
      const modal = this.element.closest('.modal')
      const modalInstance = Modal.getInstance(modal)
      modalInstance.hide()

      // Show success notification
      Notify.success('Read date successfully updated', {
        position: 'right-bottom',
        timeout: 3000,
        showOnlyTheLastOne: true
      })
    } else {
      // Show error notification if the submission failed
      Notify.failure('Failed to update read date', {
        position: 'right-bottom',
        timeout: 3000,
        showOnlyTheLastOne: true
      })
    }
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-end', this.handleSubmit)
  }
}
