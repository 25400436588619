import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="support-login"
export default class extends Controller {
  static targets = ["loginButton"]

  updateLoginMessage(event) {
    const signInDescription = document.getElementById('sign-in-description')
    if (signInDescription) {
      signInDescription.textContent = "To subscribe to The Greatest Books and become a member, please create an account or log in to your existing account."
    }
  }
}
