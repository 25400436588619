import AutoCompleteJS from "@tarekraafat/autocomplete.js"
import initAutocompleteBooks from "./autocomplete-books"

const selectionFn = async function(event) {
  const feedback = event.detail;
  let selection = feedback.selection.value.id; // Assuming the key you want is 'value'

  console.log("selection: ", selection)

  // You can access the input element using `feedback.event.target`
  const inputElement = feedback.event.target;

  // Create a hidden input to store the book ID
  const bookIdInput = document.createElement('input');
  bookIdInput.type = 'hidden';
  bookIdInput.name = 'user_list_book[book_id]'; // Ensure this matches the name expected by your Rails params
  bookIdInput.value = selection; // Assuming the ID is in the 'id' property of the selection object

  // Find the form that this input belongs to
  const form = document.getElementById("new-user-list-book-form")

  // Append the hidden input to the form
  form.appendChild(bookIdInput);

  const submitBtn = document.getElementById("autocomplete-submit-btn")
  // Submit the form
  submitBtn.click();
  document.getElementById("book_title_autocomplete").value = "";
};

document.addEventListener('turbo:frame-render', (event) => {
  const inputClass = 'book-autocomplete-survey';
  const autocompleteBookElements = document.getElementsByClassName(inputClass);

  for (let element of autocompleteBookElements) {
    // Skip if already initialized
    if (element.dataset.autocompleteInitialized === 'true') continue;

    const elementId = element.id;

    const config = initAutocompleteBooks(
      `/autocomplete`, 
      '#' + elementId, 
      selectionFn, 
      true
    );

    // Initialize the autocomplete for the current element
    const autoCompleteJS = new AutoCompleteJS(config);
    
    // Mark as initialized
    element.dataset.autocompleteInitialized = 'true';
  }
});
