import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["startDate", "endDate", "submitButton"]

  connect() {
    if (this.hasAllTargets()) {
      this.validateDates()
    }
  }

  hasAllTargets() {
    return this.hasStartDateTarget && this.hasEndDateTarget && this.hasSubmitButtonTarget
  }

  validateDates() {
    if (!this.hasAllTargets()) return

    const startDate = parseInt(this.startDateTarget.value) || null
    const endDate = parseInt(this.endDateTarget.value) || null
    
    // Reset validation state
    this.startDateTarget.setCustomValidity("")
    this.endDateTarget.setCustomValidity("")
    
    // Both dates are empty - valid
    if (startDate === null && endDate === null) {
      this.submitButtonTarget.disabled = false
      return
    }
    
    // Validate individual dates are within range
    if (startDate !== null) {
      if (startDate < -5000 || startDate > new Date().getFullYear()) {
        this.startDateTarget.setCustomValidity("Date must be between -5000 and current year")
        this.startDateTarget.reportValidity()
        this.submitButtonTarget.disabled = true
        return
      }
    }
    
    if (endDate !== null) {
      if (endDate < -5000 || endDate > new Date().getFullYear()) {
        this.endDateTarget.setCustomValidity("Date must be between -5000 and current year")
        this.endDateTarget.reportValidity()
        this.submitButtonTarget.disabled = true
        return
      }
    }
    
    // Validate start date is before end date
    if (startDate !== null && endDate !== null && startDate > endDate) {
      this.startDateTarget.setCustomValidity("Start date must be before end date")
      this.endDateTarget.setCustomValidity("End date must be after start date")
      // Show the validation message on the field that was last changed
      if (this.lastChangedField === 'start') {
        this.startDateTarget.reportValidity()
      } else {
        this.endDateTarget.reportValidity()
      }
      this.submitButtonTarget.disabled = true
      return
    }
    
    this.submitButtonTarget.disabled = false
  }

  startDateChanged() {
    this.lastChangedField = 'start'
    this.validateDates()
  }

  endDateChanged() {
    this.lastChangedField = 'end'
    this.validateDates()
  }

  submitForm(event) {
    event.preventDefault()
    
    if (!this.hasAllTargets()) return
    
    // Check validity and show messages if invalid
    if (!this.startDateTarget.validity.valid) {
      this.startDateTarget.reportValidity()
      return
    }
    
    if (!this.endDateTarget.validity.valid) {
      this.endDateTarget.reportValidity()
      return
    }

    // Get the current page URL
    let currentUrl = window.location.href
    
    // Regular expression to remove the "/page/<id>" part of the URL
    let pageRegex = /\/page\/\d+/
    let urlWithoutPage = currentUrl.replace(pageRegex, '')
    
    // Regular expression to match the date variations at the end of the URL
    let dateRegex = /(\/since\/-?\d+)|(\/to\/-?\d+)|(\/from\/-?\d+\/to\/-?\d+)$/
    let baseUrl = urlWithoutPage.replace(dateRegex, '')
    
    // Get the date values
    let dateStart = this.startDateTarget.value
    let dateEnd = this.endDateTarget.value
    
    // Check if the URL contains "the-greatest" or "the-greatest-books"
    let genreUrlPattern = /\/the-greatest\/[^\/]+\/books/
    let mainUrlPattern = /\/the-greatest-books/
    
    // Ensure baseUrl does not end with a slash before appending
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.slice(0, -1)
    }
    
    // Handle URLs with no initial filters
    if (!genreUrlPattern.test(baseUrl) && !mainUrlPattern.test(baseUrl)) {
      baseUrl += "/the-greatest-books"
    }
    
    // Append the date filters based on the user input
    if (dateStart && !dateEnd) {
      baseUrl += `/since/${dateStart}`
    } else if (dateStart && dateEnd) {
      baseUrl += `/from/${dateStart}/to/${dateEnd}`
    } else if (!dateStart && dateEnd) {
      baseUrl += `/to/${dateEnd}`
    }
    
    window.location.assign(baseUrl)
  }
} 